import React from "react"

import { withCartLogin } from "./withCartLogin"
import { AccountGuestForm } from "../../Account/Login/Form/AccountGuestForm"
import { AccountLoginForm } from "../../Account/Login/Form/AccountLoginForm"
import { AccountRegisterForm } from "../../Account/Login/Form/AccountRegisterForm"
import { Column, Row, StyledContainer, ColumnInnerWrapper, ColumnInner } from "../../Account/Login/AccountLoginStyles"

interface Props {
  handleToggle: Function
  signInActive: boolean
}

export const CartLogin = withCartLogin(({ handleToggle, signInActive }: Props) => (
  <>
    <StyledContainer>
      <Row>
        <Column border>
          <ColumnInnerWrapper isSignIn={signInActive}>
            <ColumnInner display={signInActive}>
              <AccountLoginForm handleFormToggle={handleToggle} isCheckout />
            </ColumnInner>
            <ColumnInner display={!signInActive}>
              <AccountRegisterForm handleFormToggle={handleToggle} isCheckout />
            </ColumnInner>
          </ColumnInnerWrapper>
        </Column>

        <Column>
          <AccountGuestForm />
        </Column>
      </Row>
    </StyledContainer>
  </>
))
