import React from "react"

import { withAccountGuestForm } from "./withAccountGuestForm"
import { CartFormCheckout } from "../../../Cart/Form/CartFormCheckout"
import { Checkbox } from "../../../Styled/Checkbox/Checkbox"
import { Form, FormHeading, FormLabel, FormInput, FormButtonWrapper, FormNote, FormError, FormLinkWrapper } from "../../../Styled/Form"

interface Props {
  acceptsMarketing: boolean
  error: string
  email: string
  invalid: boolean
  handleChange: Function
  handleSubscribe: Function
  handleSubmit: Function
  locales: any
  loading: boolean
}

export const AccountGuestForm = withAccountGuestForm(
  ({ acceptsMarketing, error, email, invalid, handleChange, handleSubscribe, handleSubmit, locales, loading }: Props) => (
    <Form onSubmit={handleSubmit}>
      <FormHeading>{locales?.guestCheckoutTitle}</FormHeading>

      <FormLabel>{locales?.emailAddress}</FormLabel>
      <FormInput name={`email`} value={email} onChange={handleChange} type={`email`} />

      <FormLinkWrapper>
        <Checkbox name={`acceptsMarketing`} externalState checked={acceptsMarketing} onChange={handleSubscribe}>
          {locales.subscribe}
        </Checkbox>
      </FormLinkWrapper>

      <FormButtonWrapper withGuestSpacing>
        <CartFormCheckout disabled={invalid || loading} text={locales?.guestCheckoutSubmit} />

        <FormNote>{locales?.terms}</FormNote>

        {error && <FormError>{error.message}</FormError>}
      </FormButtonWrapper>
    </Form>
  )
)
