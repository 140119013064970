import React, { useState } from "react"

export const withCartLogin = Component => ({ name = `CartLogin` }) => {
  const [signInActive, setSignInActive] = useState(true)

  const handleToggle = () => setSignInActive(prevState => !prevState)

  Component.displayName = name
  return <Component handleToggle={handleToggle} signInActive={signInActive} />
}
